import { useState, useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { confirmDeletion, requestDeleteAccount } from "./_requests";
import { useIntl } from "react-intl";
import { countDown } from "../../../_metronic/helpers/countdown";
import VerificationInput from "react-verification-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { X, CheckCircle } from "react-feather";
import { LanguagesMini } from "../../../_metronic/partials/layout/header-menus/LanguagesMini";

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email("WRONG.EMAIL.FORMAT")
    .min(3, "VALIDATION.MIN.CHARS")
    .max(50, "VALIDATION.MAX.CHARS")
    .required("VALIDATION.EMAIL"),
});

export function DeleteAccountRequest() {
  const [counter, setCounter] = useState<number>(0);
  const [countdown, setCountdown] = useState<string>("00:00");
  const [token, setToken] = useState<string>();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [activePage, setActivePage] = useState<number>(1);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [email, setEmail] = useState<string>();
  const intl = useIntl();

  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

  useEffect(() => {
    countDown(counter, setCountdown);
  }, [counter]);

  const requestConfirmDeletion = async () => {
    setLoading(true);

    await confirmDeletion(verificationCode!)
      .then(async (res: any) => {
        setCounter(0);
        //if is correct show the message
        setActivePage(3);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(intl.formatMessage({ id: "ERROR.VERIFICATION.CODE" }), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <X color="#F1595A" size={20} />,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: emailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestDeleteAccount(values.email)
          .then(({ data: { result } }) => {
            setCounter(120);
            setEmail(values.email);
            setLoading(false);
            setActivePage(2);
          })
          .catch((err) => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            toast.error(
              "ERROR: " + intl.formatMessage({ id: "ERROR.EMAIL.NOT.FOUND" }),
              {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                icon: <X color="#F1595A" size={20} />,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          });
      }, 1000);
    },
  });

  return (
    <>
      {activePage === 1 && (
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_password_reset_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="text-center mb-10">
            {/* begin::Title */}
            <h1 className="text-dark fw-bolder mb-3 main-title">
              {intl.formatMessage({ id: "DELETE.ACCOUNT" })}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className="fw-semibold fs-4 mb-6">
              {intl.formatMessage({ id: "DELETE.ACCOUNT.MESSAGE" })}
            </div>
            {/* end::Link */}

            {/* begin::Link */}
            <div className="fw-semibold fs-4">
              {intl.formatMessage({ id: "FORGOT.PASSWORD.SUMMARY" })}
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                {intl.formatMessage({ id: "SEND.EMAIL.ERROR" })}
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900">
              {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
            </label>
            <input
              type="email"
              placeholder={intl.formatMessage({ id: "TYPE.EMAIL" })}
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-white",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {intl.formatMessage({ id: formik.errors.email })}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}

          <div className="d-grid mb-10">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="button button-primary"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!loading && (
                <span className="indicator-label fs-2">
                  {intl.formatMessage({ id: "SEND.EMAIL" })}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-label fs-2"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "PLEASE.WAIT" })}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      )}
      {activePage === 2 && (
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark fw-bolder mb-3 main-title">
            {intl.formatMessage({ id: "DELETE.ACCOUNT" })}
          </h1>
          {/* end::Title */}
          <p className="text-center fs-2">
            {intl.formatMessage({ id: "ENTER.VERIFICATION.CODE" })}{" "}
            <span className="text-accent-blue">{email}</span>
          </p>

          <div className="d-flex flex-center my-4">
            <div className="custom-styles my-4">
              <VerificationInput
                onChange={setVerificationCode}
                length={5}
                placeholder=""
                classNames={{
                  container: "code-container",
                  character: "code-character",
                  characterInactive: "code-character--inactive",
                  characterSelected: "code-character--selected",
                }}
              />
            </div>
          </div>
          {counter > 0 && (
            <p className="text-center fs-3 my-4">
              {intl.formatMessage({ id: "RESEND.CODE.IN" })}{" "}
              <span className="text-primary">{countdown}</span>
            </p>
          )}
          <p className="text-center fs-2 my-4">
            {intl.formatMessage({ id: "DONT.RECEIVE.CODE" })}{" "}
            <button
              disabled={counter > 0}
              onClick={async (e) => {
                e.currentTarget.disabled = true;
                await requestDeleteAccount(email!);
                setCounter(120);
                e.currentTarget.disabled = false;
              }}
              className="btn btn-link fw-bold text-accent-blue fs-2"
            >
              {intl.formatMessage({ id: "RESEND.CODE" })}
            </button>
          </p>
          <div className="d-grid mb-10">
            <button
              className="button button-primary mt-4"
              disabled={!verificationCode || loading}
              onClick={requestConfirmDeletion}
            >
              {!loading && (
                <span className="indicator-label fs-2">
                  {intl.formatMessage({ id: "VERIFY.CODE" })}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-label fs-2"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "PLEASE.WAIT" })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      )}
      {activePage === 3 && (
        <div className="text-center">
          <CheckCircle color="green" size={100} className="mb-8" />

          <p className="text-center fs-1 fw-bolder leading-10">
            {intl.formatMessage({ id: "DELETE.ACCOUNT.RESULT" })}

          </p>

        </div>
      )}
      <div className="my-14"><LanguagesMini /></div>
      <ToastContainer />
    </>
  );
}
