/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";
import * as Icon from "react-feather";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { currentUser } = useAuth();

  const patientUrl = `/patients/${currentUser?.user.id}/list`;
  

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        icon={<Icon.BarChart2 />}
      />
      {currentUser?.rol_id == 1 && (
        <>
          <SidebarMenuItem
            to="/owners"
            title={intl.formatMessage({ id: "MENU.OWNERS" })}
            icon={<Icon.Users />}
          />

          <SidebarMenuItem
            to="corporate-accounts"
            title={intl.formatMessage({ id: "MENU.CORPORATE.ACCOUNTS" })}
            icon={<Icon.Grid />}
          />
          <SidebarMenuItem
            to="admins"
            title={intl.formatMessage({ id: "MENU.ADMINS" })}
            icon={<Icon.Clipboard />}
          />
        </>
      )}
      {currentUser?.rol_id == 2 && (
        <>
          <SidebarMenuItem
            to={patientUrl}
            title={intl.formatMessage({ id: "MENU.PATIENTS" })}
            icon={<Icon.Users />}
          />
        </>
      )}
      <SidebarMenuItem
        to="profile"
        title={intl.formatMessage({ id: "MENU.PROFILE" })}
        icon={<Icon.User />}
      />
    </>
  );
};

export { SidebarMenuMain };
