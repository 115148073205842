/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { useLang, setLanguage } from "../../../i18n/Metronici18n";
import { useIntl } from "react-intl";

const languages = [
  {
    lang: "en",
    name: "LANG.ENGLISH",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    lang: "es",
    name: "LANG.SPANISH",
    flag: toAbsoluteUrl("/media/flags/spain.svg"),
  },
];

const LanguagesMini: FC = () => {
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  const intl = useIntl();
  return (
    <div className="d-flex flex-center flex-row-fluid pt-12">
      {languages.map((l) => (
        <div
          className="flagLang"
          key={l.lang}
          onClick={() => {
            setLanguage(l.lang);
            localStorage.setItem("lang", l.lang);
          }}
        >
          <a
            href="#"
            className={clsx("menu-link d-flex px-5", {
              active: l.lang === currentLanguage?.lang,
            })}
          >
            <span className="symbol symbol-20px me-4">
              <img className="rounded-1" src={l.flag} alt={l.name} />
            </span>
            {intl.formatMessage({ id: l.name })}
          </a>
        </div>
      ))}
    </div>
  );
};

export { LanguagesMini };
