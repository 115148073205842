import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { ProfileWrapper } from "../pages/profile/ProfileWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { useAuth } from "../../app/modules/auth";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const OwnersPage = lazy(
    () => import("../modules/apps/owners-management/OwnersPage")
  );

  const PatientsPage = lazy(
    () => import("../modules/apps/patients-management/PatientsPage")
  );

  const AdminsPage = lazy(
    () => import("../modules/apps/admins-management/AdminsPage")
  );

  const CorporateAccountsPage = lazy(
    () =>
      import(
        "../modules/apps/corporate-accounts-management/CorporateAccountsPage"
      )
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="profile" element={<ProfileWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        {currentUser?.rol_id == 1 && (
          <>
            <Route
              path="/*"
              element={
                <SuspensedView>
                  <OwnersPage />
                </SuspensedView>
              }
            />
            <Route
              path="admins/*"
              element={
                <SuspensedView>
                  <AdminsPage />
                </SuspensedView>
              }
            />
            <Route
              path="corporate-accounts/*"
              element={
                <SuspensedView>
                  <CorporateAccountsPage />
                </SuspensedView>
              }
            />
          </>
        )}

        <Route
          path="patients/:userId/*"
          element={
            <SuspensedView>
              <PatientsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
