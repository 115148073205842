import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const CONFIRM_DELETION_URL = `${API_URL}/api/v1/user/confirm-deletion`;
export const DELETE_ACCOUNT_REQUEST_URL = `${API_URL}/api/v1/user/request-account-deletion`;


axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("lang")!;


export function requestDeleteAccount(email: string) {
  return axios.post<{ result: boolean }>(DELETE_ACCOUNT_REQUEST_URL, {
    email,
  });
}

export function confirmDeletion(code: string) {
  return axios.post(`${CONFIRM_DELETION_URL}`, {
    code,
  });
}

