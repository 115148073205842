export const countDown = (
    duration: number,
    setCountdown: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let timer: any = duration,
      minutes,
      seconds;

    setTimeout(() => {
      minutes = timer / 60;
      seconds = timer % 60;

      minutes = minutes < 10 ? "0" + Math.floor(minutes) : minutes;
      seconds = seconds < 10 ? "0" + Math.floor(seconds) : seconds;

      setCountdown(minutes + ":" + seconds);
    }, 1000);
  };