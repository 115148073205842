/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { createAdmin } from "../core/_requests";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import { CheckCircle, Eye, EyeOff } from "react-feather";
import { useSearchParams, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { LanguagesMini } from "../../../../_metronic/partials/layout/header-menus/LanguagesMini";

const initialValues = {
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  confirmpassword: "",
};

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, "VALIDATION.MIN.CHARS")
    .max(50, "VALIDATION.MAX.CHARS")
    .required("VALIDATION.FIRST.NAME"),
  last_name: Yup.string()
    .min(3, "VALIDATION.MIN.CHARS")
    .max(50, "VALIDATION.MAX.CHARS")
    .required("VALIDATION.LAST.NAME"),
  password: Yup.string()
    .matches(/([-!@#$%^&*=+'";:?.,<>_])/, "VALIDATION.SPECIAL.CHAR")
    .matches(/\d/, "VALIDATION.PASS.NUMBER")
    .matches(/(?=.*?[a-zA-Z])/, "VALIDATION.PASS.LETTER")
    .matches(/(?=.*[A-Z])(?=.*[a-z])/, "VALIDATION.PASS.UPPERLOWER")
    .min(8, "VALIDATION.PASS.MIN")
    .required("VALIDATION.PASS"),
  confirmpassword: Yup.string()
    .required("VALIDATION.CONFIRM.PASS")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "VALIDATION.PASS.DONTMATCH"
      ),
    }),
});

export function Registration() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordConfirmShown, setPasswordConfirmShown] =
    useState<boolean>(false);
  const [eyeIcon, setEyeIcon] = useState<any>(<EyeOff color="#171717" />);
  const [confirmEyeIcon, setConfirmEyeIcon] = useState<any>(
    <EyeOff color="#171717" />
  );
  const intl = useIntl();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    if (!passwordShown) {
      setEyeIcon(<Eye color="#171717" />);
    } else {
      setEyeIcon(<EyeOff color="#171717" />);
    }
  };
  const togglePasswordConfirm = () => {
    setPasswordConfirmShown(!passwordConfirmShown);
    if (!passwordConfirmShown) {
      setConfirmEyeIcon(<Eye color="#171717" />);
    } else {
      setConfirmEyeIcon(<EyeOff color="#171717" />);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (email) {
        try {
          await createAdmin(
            email!,
            values.first_name,
            values.last_name,
            values.password
          ).then((res) => {
            setShowModal(true);
            setLoading(false);
          });
        } catch (error) {
          saveAuth(undefined);
          setStatus(`The registration details is incorrect: ${error}`);
          setSubmitting(false);
          setLoading(false);
        }
      } else {
        setStatus("Email is missing, try open the invitation link again");
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3 main-title">
          {intl.formatMessage({id: "WELCOME.TO.ZEALACARE"})}
        </h1>
        {/* end::Title */}

        <div className="fw-semibold fs-2">
        {intl.formatMessage({id: "WELCOME.TO.ZEALACARE.SUMMARY"})}
        </div>
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark">
          {intl.formatMessage({ id: "FIRST.NAME" })}
        </label>
        <input
          placeholder={intl.formatMessage({id: "TYPE.FIRST.NAME"})}
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("first_name")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.first_name && formik.errors.first_name,
            },
            {
              "is-valid":
                formik.touched.first_name && !formik.errors.first_name,
            }
          )}
        />
        {formik.touched.first_name && formik.errors.first_name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">
                {intl.formatMessage({ id: formik.errors.first_name })}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark">
          {intl.formatMessage({ id: "LAST.NAME" })}
        </label>
        <input
          placeholder={intl.formatMessage({id: "TYPE.LAST.NAME"})}
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("last_name")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.last_name && formik.errors.last_name,
            },
            {
              "is-valid": formik.touched.last_name && !formik.errors.last_name,
            }
          )}
        />
        {formik.touched.last_name && formik.errors.last_name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{intl.formatMessage({ id: formik.errors.last_name })}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark">{intl.formatMessage({id: "AUTH.INPUT.PASSWORD"})}</label>
          <div className="input-group mb-3">
            <input
              type={passwordShown ? "text" : "password"}
              autoComplete="off"
              maxLength={50}
              placeholder={intl.formatMessage({id: "TYPE.PASSWORD"})}
              {...formik.getFieldProps("password")}
              style={{ paddingRight: 40, borderRadius: 10 }}
              className={clsx(
                "form-control bg-white",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span className="icon-right-inside" onClick={togglePassword}>
              {eyeIcon}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{intl.formatMessage({ id: formik.errors.password })}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark">
          {intl.formatMessage({id: "AUTH.INPUT.CONFIRM.PASSWORD"})}
        </label>
        <div className="input-group mb-3">
          <input
            type={passwordConfirmShown ? "text" : "password"}
            autoComplete="off"
            maxLength={50}
            placeholder={intl.formatMessage({id: "CONFIRM.YOUR.PASSWORD"})}
            {...formik.getFieldProps("confirmpassword")}
            style={{ paddingRight: 40, borderRadius: 10 }}
            className={clsx(
              "form-control bg-white",
              {
                "is-invalid":
                  formik.touched.confirmpassword &&
                  formik.errors.confirmpassword,
              },
              {
                "is-valid":
                  formik.touched.confirmpassword &&
                  !formik.errors.confirmpassword,
              }
            )}
          />
          <span className="icon-right-inside" onClick={togglePasswordConfirm}>
            {confirmEyeIcon}
          </span>
        </div>
        {formik.touched.confirmpassword && formik.errors.confirmpassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{intl.formatMessage({ id: formik.errors.confirmpassword })}</span>
            </div>
          </div>
        )}
        <div>
          <ul className="list-unstyled mt-4 fs-4">
            <li>
              <CheckCircle color="#33C057" size={16} /> {intl.formatMessage({id: "PASSWORD.TIP1"})}
            </li>
            <li>
              <CheckCircle color="#33C057" size={16} /> {intl.formatMessage({id: "PASSWORD.TIP2"})}
            </li>
            <li>
              <CheckCircle color="#33C057" size={16} /> {intl.formatMessage({id: "PASSWORD.TIP3"})}
            </li>
          </ul>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="button button-primary mt-6 w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">{intl.formatMessage({id: "CREATE.PROFILE"})}</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({id: "PLEASE.WAIT"})}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}

      {showModal && (
        <div>
          <div
            className="modal d-block text-start"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            {/* begin::Modal dialog */}
            <div className="modal-dialog modal-dialog-centered mw-550px">
              {/* begin::Modal content */}
              <div className="modal-content">
                <div className="modal-header">
                  <h1>Admin has been created successfully</h1>
                </div>
                {/* begin::Modal body */}
                <div className="modal-body scroll-y fs-4">
                  <div className="d-flex moday-footer mt-12 flex-center">
                    <button className="d-flex btn button-primary-rounded">
                      <Link to="/">
                        <span className="text-white">Go to Login</span>
                      </Link>
                    </button>
                  </div>
                </div>

                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className="modal-backdrop fade show"></div>
          {/* end::Modal Backdrop */}
        </div>
      )}
      <div className="my-14"><LanguagesMini /></div>
    </form>
    
  );
}
