/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import * as Icon from "react-feather";
type Props = {
  color?: string;
  avatar?: string;
  name: string;
  email?: string;
};

const ProfileHeader: FC<Props> = ({ color = "", avatar = "", name, email }) => {
  return (
    <div>
      <div className="d-flex flex-left flex-row mt-6">
        <div className="mb-5 d-flex align-items-center justify-content-center">
          {!avatar ? (
            <div className="symbol w-75px h-75px symbol-circle bg-primary align-items-center d-flex justify-content-center">
              <span className={`fs-5 fw-bolder `}>
                <Icon.User color="white" size={42} />
              </span>
            </div>
          ) : (
            <div className="round-container">
              <img
                alt="Pic"
                src={toAbsoluteUrl(avatar)}
                className="bg-primary"
              />
            </div>
          )}
        </div>

        {email!.length > 0 ? (
          <div className="flex-col align-items-left align-middle">
            <p className="fs-1 text-gray-800 text-hover-primary mx-6 fw-bolder d-flex my-2">
              {name}
            </p>
            <p className="fs-4 text-gray-800 text-hover-primary mx-6 my-0">
              {email}
            </p>
          </div>
        ) : (
          <p className="fs-1 text-gray-800 text-hover-primary mx-6 fw-bolder d-flex align-items-center justify-content-center">
            {name}
          </p>
        )}
      </div>
    </div>
  );
};

export { ProfileHeader };
