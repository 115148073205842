import clsx from "clsx";
import { KTSVG } from "../../../helpers";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { ThemeModeType, useThemeMode } from "./ThemeModeProvider";
import { useIntl } from "react-intl";

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

const ThemeModeSwitcher = ({
  toggleBtnClass = "",
  toggleBtnIconClass = "svg-icon-2",
  menuPlacement = "bottom-end",
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };

  const intl = useIntl();

  return (
    <>
      {/* begin::Menu toggle */}
      <div
        className="menu-item px-5 position-relative"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          {calculatedMode === "dark" && (
            <>
              <KTSVG
                path="/media/icons/gen061.svg"
                className={clsx("theme-light-hide", toggleBtnIconClass)}
              />
              <div className="menu-item mx-3">
                {intl.formatMessage({ id: "DARK" })}
              </div>
            </>
          )}

          {calculatedMode === "light" && (
            <>
              <KTSVG
                path="/media/icons/gen060.svg"
                className={clsx("theme-dark-hide", toggleBtnIconClass)}
              />
              <div className="menu-item mx-3">
                {intl.formatMessage({ id: "LIGHT" })}
              </div>
            </>
          )}
        </a>
      </div>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
        data-kt-menu="true"
      >
        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx("menu-link px-3 py-2", {
              active: menuMode === "light",
            })}
            onClick={() => switchMode("light")}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTSVG path="/media/icons/gen060.svg" className="svg-icon-3" />
            </span>
            <span className="menu-item">
              {intl.formatMessage({ id: "LIGHT" })}
            </span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx("menu-link px-3 py-2", {
              active: menuMode === "dark",
            })}
            onClick={() => switchMode("dark")}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTSVG path="/media/icons/gen061.svg" className="svg-icon-3" />
            </span>
            <span className="menu-item">
              {intl.formatMessage({ id: "DARK" })}
            </span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx("menu-link px-3 py-2", {
              active: menuMode === "system",
            })}
            onClick={() => switchMode("system")}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTSVG path="/media/icons/gen062.svg" className="svg-icon-3" />
            </span>
            <span className="menu-item">
              {intl.formatMessage({ id: "SYSTEM" })}
            </span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { ThemeModeSwitcher };
