import clsx from "clsx";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";
import * as Icon from "react-feather";

const itemClass = "ms-1 ms-lg-3";
const userAvatarClass = "symbol-35px symbol-md-40px";

const Navbar = () => {
  const { currentUser } = useAuth();

  return (
    <div className="app-navbar">
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer flex-row symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              {currentUser?.picture_url ? (
                <div className="symbol w-40px h-40px symbol-circle bg-primary align-items-center d-flex justify-content-center">
                  <span className={`fs-5 fw-bolder `}>
                    <Icon.User color="white" size={30} />
                  </span>
                </div>
              ) : (
                <div className="round-container-min">
                  <img alt="Logo" src={currentUser?.picture_url} />
                </div>
              )}

              <div className="d-flex flex-column mx-3">
                <div className="fw-bolder d-flex align-items-center fs-3">
                  {currentUser?.first_name} {currentUser?.last_name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
