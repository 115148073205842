/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import clsx from "clsx";
import { Download, Loader } from "react-feather";
import { useIntl } from "react-intl";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";

const filter = (node: HTMLElement) => {
  const exclusionClasses = [
    "btnDownloadPdf",
    "pagination",
    "search-component",
    "tr:last",
  ];
  return !exclusionClasses.some((classname) =>
    node.classList?.contains(classname)
  );
};

const downloadPDF = (setLoading: any, title: string) => {
  setLoading(true);
  htmlToImage
    .toPng(document.getElementById("kt_app_main")!, {
      quality: 0.95,
      filter: filter,
    })
    .then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      var img = new Image();
      img.src = dataUrl;
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img.src, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(title + ".pdf");
      setLoading(false);
    });
};

const ExportPDFButton = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <a
      onClick={() =>
        downloadPDF(setLoading, intl.formatMessage({ id: "MENU.DASHBOARD" }))
      }
      className={clsx(
        "btnDownloadPdf button button-secondary h-22 d-flex align-items-center position-absolute end-0"
      )}
      style={{ top: "-60px", cursor: "pointer" }}
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
    >
      {!loading && <Download size={22} className="mx-3" />}
      {loading && <Loader size={22} className="mx-3 spinner" />}
      &nbsp;{" "}
      <span className="fs-3">{intl.formatMessage({ id: "EXPORT.PDF" })}</span>
    </a>
  );
};

export { ExportPDFButton };
