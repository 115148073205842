/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import { useIntl } from "react-intl";
import { getNotifications } from "../_requests";
type Props = {
  className: string;
};

const BriefChangeChartsWidget: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();
  const [notifications, setNotifications] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [briefChanges, setBriefChanges] = useState<any>([]);
  const [time, setTime] = useState<string>("day");
  useEffect(() => {
    getNotifications(time).then((res: any) => {
      setNotifications(res.notifications);
      setBriefChanges(res.briefChanges);
      setValues(res.dayWeekMonth)
    });
  }, [time]);

  const clearActive = (event: any) => {
    var month = document.getElementById("linkNotificationMonth");
    var week = document.getElementById("linkNotificationWeek");
    var day = document.getElementById("linkNotificationDay");
    month?.classList.remove("active");
    week?.classList.remove("active");
    day?.classList.remove("active");
    (event?.target as Element).classList.add('active');
  }
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, intl, notifications, briefChanges, values)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, notifications]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header header-graph border-0 pt-5">
        <h3 className="card-title align-items-end flex-column">
          <span className="card-label fw-bold mb-1">
            {intl.formatMessage({ id: "BRIEF.CHANGE.VS" })}
          </span>

          {/*<span className='text-muted fw-semibold fs-7'>More than 500 new orders</span>*/}
        </h3>

        {/* begin::Toolbar */}

        <div className="card-toolbar mx-11" data-kt-buttons="true">
          <a
            className="button-tab px-4"
            id="linkNotificationMonth"
            onClick={(event) => {

              setTime("month");
              clearActive(event);

            }}
          >
            {intl.formatMessage({ id: "MONTH" })}
          </a>

          <a
            className="button-tab px-4"
            id="linkNotificationWeek"
            onClick={(event) => {

              setTime("week");
              clearActive(event);

            }}
          >
            {intl.formatMessage({ id: "WEEK" })}
          </a>

          <a
            className="button-tab px-4 active"
            id="linkNotificationDay"
            onClick={(event) => {

              setTime("day");
              clearActive(event);

            }}
          >
            {intl.formatMessage({ id: "DAY" })}
          </a>
        </div>

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div
        className="card-body-graph mt-6"
        style={{ marginBottom: 0, paddingBottom: 0 }}
      >
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_8_chart"
          style={{ height: "350px", paddingBottom: 0, marginBottom: 0 }}
          className="card-rounded-bottom"
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { BriefChangeChartsWidget };

function getChartOptions(height: number, Intl: any, notifications: any, briefChanges: any, values: any): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const strokeColor = getCSSVariableValue("--bs-gray-300");

  const color1 = "#3297D3";
  const color1Light = "#EBF5FB";

  const color2 = "#A49ADB";
  const color2Light = "#D8DAF3";

  return {
    series: [
      {
        name: Intl.formatMessage({ id: "NOTIFICATIONS" }),
        //data: [6, 10, 10, 20, 20, 10],
        data: notifications,
      },
      {
        name: Intl.formatMessage({ id: "BRIEF.CHANGE" }),
        //data: [5, 7, 7, 18, 18, 8],
        data: briefChanges,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      fontSize: "14px",
      markers: {
        width: 60,
        height: 8,
        fillColors: [color2, color1],
      },
    },
    dataLabels: {
      enabled: false,
    },

    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 4,
      colors: [color1, color2],
    },

    xaxis: {
      categories: values,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: true,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
      marker: {
        fillColors: [color1, color2],
      },
    },
    colors: [color1Light, color2Light],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      padding: {
        top: 0,
        bottom: 0,
        left: 22,
        right: 0,
      },
    },
    markers: {
      colors: [color1, color2],
      strokeColors: [color1, color2],
      strokeWidth: 4,
    },
  };
}
