/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { Languages } from "./Languages";
import { ThemeModeSwitcher } from "../../../partials";
import { useIntl } from "react-intl";

const HeaderUserMenu: FC = () => {
  const { logout } = useAuth();
  const intl = useIntl();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-5">
        <Link to={"/profile"} className="menu-link px-5">
          {intl.formatMessage({ id: "MY.PROFILE" })}
        </Link>
      </div>

      <ThemeModeSwitcher />

      <div className="separator my-2"></div>

      <Languages />

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          {intl.formatMessage({ id: "LOGOUT" })}
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
