/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { PageTitle } from "../../../_metronic/layout/core";
import { ProfileHeader } from "../../../_metronic/partials/content/cards/ProfileHeader";
import { useAuth } from "../../../app/modules/auth";
import { Mail, Check, X } from "react-feather";
import {
  updateUser,
  getUser,
  requestVerifyCode,
  updateEmail,
} from "../../modules/auth/core/_requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { countDown } from "../../../_metronic/helpers/countdown";

import VerificationInput from "react-verification-input";

const ProfileWrapper: FC = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>();
  const [counter, setCounter] = useState<number>(0);
  const [countdown, setCountdown] = useState<string>("00:00");
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const { currentUser } = useAuth();

  const initialValues = {
    email: currentUser?.user.email,
    first_name: currentUser?.first_name,
    last_name: currentUser?.last_name,
  };

  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  const [loading, setLoading] = useState(false);

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("VALIDATION.INVALID.EMAIL")
      .required("VALIDATION.EMAIL"),
    first_name: Yup.string()
      .min(3, "VALIDATION.MIN.CHARS")
      .max(50, "VALIDATION.MAX.CHARS")
      .required("VALIDATION.FIRST.NAME"),
    last_name: Yup.string()
      .min(3, "VALIDATION.MIN.CHARS")
      .max(50, "VALIDATION.MAX.CHARS")
      .required("VALIDATION.LAST.NAME"),
  });

  const { setCurrentUser } = useAuth();

  const sendVerifyCode = async (email: string) => {
    setLoading(true);
    await requestVerifyCode(email)
      .then(async (res) => {
        setShowModal(true);
        setIsRunning(true);
        {
          counter == 0 && setCounter(120);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(intl.formatMessage({ id: "ERROR.EMAIL.EXISTS" }), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <X color="#F1595A" size={20} />,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const verifyEmail = async () => {
    setLoading(true);
    await updateEmail(
      Number(currentUser?.user.id),
      newEmail!,
      verificationCode!
    )
      .then(async (res) => {
        setCounter(0);
        setIsRunning(false);
        const { data: user } = await getUser();
        setCurrentUser(user);
        toast.success(intl.formatMessage({ id: "EMAIL.UPDATED" }), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <Check color="#33C057" size={20} />,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowModal(false);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(intl.formatMessage({ id: "ERROR.VERIFICATION.CODE" }), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <X color="#F1595A" size={20} />,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    countDown(counter, setCountdown);
  }, [counter]);

  useEffect(() => {
    console.log(currentUser);
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (
          values.first_name !== currentUser?.first_name ||
          values.last_name !== currentUser?.last_name ||
          values.email !== currentUser?.user.email
        ) {
          await updateUser(
            Number(currentUser?.user.id),
            String(values.first_name),
            String(values.last_name)
          ).then(async (res) => {
            const { data: user } = await getUser();
            setCurrentUser(user);

            if (
              values.first_name !== currentUser?.first_name ||
              values.last_name !== currentUser?.last_name
            ) {
              toast.success(intl.formatMessage({ id: "CHANGES.SAVED" }), {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                icon: <Check color="#33C057" size={20} />,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            if (values.email !== currentUser?.user.email) {
              initialValues.email = values.email;
              setNewEmail(values.email);
              sendVerifyCode(String(values.email));
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "ERROR.MESSAGE" }), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <X color="#F1595A" size={20} />,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.PROFILE" })}
      </PageTitle>
      <div className="row gy-5 gx-xl-6 col-sm-12 col-lg-6">
        <div className="col-12">
          <ProfileHeader
            avatar=""
            name={currentUser?.first_name + " " + currentUser?.last_name}
            email={currentUser?.rol_id == 2 ? currentUser.user.email : ""}
          />
        </div>
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_signup_form"
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Firstname */}
          <div className="fv-row mb-8">
            <label className="form-label-min fw-bolder text-dark">
              {intl.formatMessage({ id: "FIRST.NAME" })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: "TYPE.FIRST.NAME" })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("first_name")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.first_name && formik.errors.first_name,
                },
                {
                  "is-valid":
                    formik.touched.first_name && !formik.errors.first_name,
                }
              )}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {intl.formatMessage({ id: formik.errors.first_name })}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Firstname */}
          <div className="fv-row mb-8">
            <label className="form-label-min fw-bolder text-dark">
              {intl.formatMessage({ id: "LAST.NAME" })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: "TYPE.LAST.NAME" })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("last_name")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.last_name && formik.errors.last_name,
                },
                {
                  "is-valid":
                    formik.touched.last_name && !formik.errors.last_name,
                }
              )}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {intl.formatMessage({ id: formik.errors.last_name })}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Email */}
          {currentUser?.rol_id === 1 && (
            <div className="fv-row mb-8">
              <label className="form-label-min fw-bolder text-dark">
                {intl.formatMessage({ id: "CURRENT.EMAIL" })}
              </label>
              <div className="input-group mb-3">
                <span className="icon-left-inside">
                  <Mail color="#444444" />
                </span>
                <input
                  placeholder="Type your email"
                  type="text"
                  autoComplete="off"
                  style={{ paddingLeft: 40, borderRadius: 10 }}
                  {...formik.getFieldProps("email")}
                  className={clsx(
                    "form-control bg-transparent rounded-left",
                    {
                      "is-invalid": formik.touched.email && formik.errors.email,
                    },
                    {
                      "is-valid": formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {intl.formatMessage({ id: formik.errors.email })}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="row">
            <div className="col-12">
              {isRunning && counter > 0 && (
                <p className="text-center fs-3 my-4">
                  {intl.formatMessage({ id: "RESEND.CODE.IN" })}{" "}
                  <span className="text-primary">{countdown}</span>
                </p>
              )}
            </div>
            <div className="col-6">
              <button
                type="submit"
                id="kt_sign_up_submit"
                className="button button-primary mt-6 w-100 mb-5"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  (isRunning && counter > 0) ||
                  loading
                }
              >
                {!loading && (
                  <span className="indicator-label">
                    {intl.formatMessage({ id: "SAVE.CHANGES" })}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "PLEASE.WAIT" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            <div className="col-6">
              <button
                type="reset"
                id="kt_sign_up_submit"
                className="button button-secondary mt-6 w-100 mb-5"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                <span className="indicator-label">
                  {intl.formatMessage({ id: "CANCEL" })}
                </span>
              </button>
            </div>
          </div>
          {/* end::Form group */}
        </form>
      </div>
      <ToastContainer />
      {showModal && (
        <div>
          <div
            className="modal d-block text-start"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            {/* begin::Modal dialog */}
            <div className="modal-dialog modal-dialog-centered mw-800px">
              {/* begin::Modal content */}
              <div className="modal-content justify-content-center d-flex flex-center">
                <div className="modal-header">
                  <h1 className="modal-title">
                    {intl.formatMessage({ id: "CHANGE.CURRENT.EMAIL" })}
                  </h1>
                </div>
                {/* begin::Modal body */}
                <div className="modal-body scroll-y fs-4">
                  <p className="text-center fs-2">
                    {intl.formatMessage({ id: "ENTER.VERIFICATION.CODE" })}{" "}
                    <span className="text-accent-blue">{newEmail}</span>
                  </p>

                  <div className="d-flex flex-center my-4">
                    <div className="custom-styles my-4">
                      <VerificationInput
                        onChange={setVerificationCode}
                        length={5}
                        placeholder=""
                        classNames={{
                          container: "code-container",
                          character: "code-character",
                          characterInactive: "code-character--inactive",
                          characterSelected: "code-character--selected",
                        }}
                      />
                    </div>
                  </div>
                  {isRunning && counter > 0 && (
                    <p className="text-center fs-3 my-4">
                      {intl.formatMessage({ id: "RESEND.CODE.IN" })}{" "}
                      <span className="text-primary">{countdown}</span>
                    </p>
                  )}
                  <p className="text-center fs-2 my-4">
                    {intl.formatMessage({ id: "DONT.RECEIVE.CODE" })}{" "}
                    <button
                      disabled={counter > 0}
                      onClick={async (e) => {
                        e.currentTarget.disabled = true;
                        await sendVerifyCode(newEmail!);
                        e.currentTarget.disabled = false;
                      }}
                      className="btn btn-link fw-bold text-accent-blue fs-2"
                    >
                      {intl.formatMessage({ id: "RESEND.CODE" })}
                    </button>
                  </p>
                  <div className="d-flex moday-footer mt-12 flex-center">
                    <button
                      className="d-flex btn button-primary-rounded mx-6 px-12"
                      disabled={!verificationCode || loading}
                      onClick={verifyEmail}
                    >
                      {!loading && (
                        <span className="indicator-label fs-2">
                          {intl.formatMessage({ id: "VERIFY.CODE" })}
                        </span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress fs-2"
                          style={{ display: "block" }}
                        >
                          {intl.formatMessage({ id: "PLEASE.WAIT" })}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <button
                      className="d-flex btn button-secondary-rounded mx-6 fw-bold"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      <span className="text-primary fs-2">
                        {intl.formatMessage({ id: "CANCEL" })}
                      </span>
                    </button>
                  </div>
                </div>

                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className="modal-backdrop fade show"></div>
          {/* end::Modal Backdrop */}
        </div>
      )}
    </>
  );
};

export { ProfileWrapper };
