/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { useIntl } from "react-intl";
type Props = {
  className: string;
  title?: string;
};

const UsersByFacilityTableWidget: React.FC<Props> = ({ className, title }) => {
  const intl = useIntl();
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold mb-1">
            {intl.formatMessage({ id: title })}
          </span>
          {/*<span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span>*/}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 w-650px align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted">
                <th className="w-250px">
                  {intl.formatMessage({ id: "FACILITIES" })}
                </th>
                <th className="min-w-140px text-center">
                  {intl.formatMessage({ id: "CAREGIVERS" })}
                </th>
                <th className="min-w-120px text-center">
                  {intl.formatMessage({ id: "MENU.PATIENTS" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src="https://raw.githubusercontent.com/vsalguero/files/main/Avatar1.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Facility 1
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    5
                  </a>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    15
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src="https://raw.githubusercontent.com/vsalguero/files/main/Avatar1.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Facility 2
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    2
                  </a>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    20
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src="https://raw.githubusercontent.com/vsalguero/files/main/Avatar1.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Facility 3
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    3
                  </a>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    31
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src="https://raw.githubusercontent.com/vsalguero/files/main/Avatar1.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Facility 4
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    5
                  </a>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    10
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                      <img
                        src="https://raw.githubusercontent.com/vsalguero/files/main/Avatar1.png"
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Facility 5
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    5
                  </a>
                </td>
                <td className="text-center">
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block fs-6"
                  >
                    15
                  </a>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { UsersByFacilityTableWidget };
