/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUser, login, appleLogin, googleLogin } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import AppleLogin from "react-apple-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Eye, EyeOff } from "react-feather";
import { useIntl } from "react-intl";
import { LanguagesMini } from "../../../../_metronic/partials/layout/header-menus/LanguagesMini";

const CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
const REDIRECT_URL = process.env.REACT_APP_APPLE_REDIRECT_URI;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("WRONG.EMAIL.FORMAT")
    .min(3, "VALIDATION.MIN.CHARS")
    .max(50, "VALIDATION.MAX.CHARS")
    .required("VALIDATION.EMAIL"),
  password: Yup.string()
    .min(8, "VALIDATION.PASS.MIN")
    .required("VALIDATION.PASS"),
});

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState<boolean>(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [eyeIcon, setEyeIcon] = useState<any>(<EyeOff color="#171717" />);
  const [response, setResponse] = useState<any>();
  const intl = useIntl();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    if (!passwordShown) {
      setEyeIcon(<Eye color="#171717" />);
    } else {
      setEyeIcon(<EyeOff color="#171717" />);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { data: auth } = await googleLogin(tokenResponse.access_token);
      saveAuth(auth);
      const { data: user } = await getUser();
      setCurrentUser(user);
    },
    onError: () => {
      formik.setStatus(intl.formatMessage({ id: "LOGIN.INCORRECT" }));
    },
  });

  const appleResponse = async (response: any) => {
    if (!response.error) {
      const { data: auth } = await appleLogin(response.authorization.id_token);
      saveAuth(auth);
      const { data: user } = await getUser();
      setCurrentUser(user);
    } else {
      saveAuth(undefined);
      formik.setStatus(intl.formatMessage({ id: "LOGIN.INCORRECT" }));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        saveAuth(auth);
        const { data: user } = await getUser();
        setCurrentUser(user);
        if (user.rol_id === 3) {
          saveAuth(undefined);
          setCurrentUser(undefined);
          setLoading(false);
          formik.setStatus(
            intl.formatMessage({ id: "USER.WITHOUT.PERMISSIONS" })
          );
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus(intl.formatMessage({ id: "LOGIN.INCORRECT" }));
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3 main-title">
          {intl.formatMessage({ id: "LOGIN.ZEALACARE" })}
        </h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark">
          {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "TYPE.EMAIL" })}
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-white",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">
                {intl.formatMessage({ id: formik.errors.email })}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row">
        <label className="form-label fw-bolder text-dark">
          {intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
        </label>

        <div className="input-group">
          <input
            type={passwordShown ? "text" : "password"}
            autoComplete="off"
            maxLength={50}
            placeholder={intl.formatMessage({ id: "TYPE.PASSWORD" })}
            {...formik.getFieldProps("password")}
            style={{ paddingRight: 40, borderRadius: 10 }}
            className={clsx(
              "form-control bg-white",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              }
            )}
          />
          <span className="icon-right-inside" onClick={togglePassword}>
            {eyeIcon}
          </span>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">
                {intl.formatMessage({ id: formik.errors.password })}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <br />
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      {/* begin::Wrapper */}
      <div className="text-right fs-base fw-semibold mb-8">
        {/* begin::Link */}
        <Link to="/auth/forgot-password" className="link-primary fs-4">
          {intl.formatMessage({ id: "FORGOT.PASSWORD" })}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="button button-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label fs-2">
              {intl.formatMessage({ id: "LOG.IN" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "PLEASE.WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className="my-14"><LanguagesMini /></div>
      

      {/* begin::Login options */}
      {/*
      <div className="separator separator-content my-14">
        <span className="w-125px text-gray-600 fw-semibold fs-4">
          {intl.formatMessage({ id: "OR" })}
        </span>
      </div>
     
      <div className="row g-3 mb-9">
       
        <a
          href="#"
          onClick={() => loginWithGoogle()}
          className="button button-outline btn-text-gray-700 btn-active-color-primary my-2 bg-state-light bg-white flex-center text-nowrap w-100"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/google-icon.svg")}
            className="h-15px me-3"
          />
          <span className="indicator-label-small text-blue">
            {intl.formatMessage({ id: "LOGIN.WITH.GOOGLE" })}
          </span>
        </a>

      

        <AppleLogin
          clientId={`${CLIENT_ID}`}
          redirectURI={`${REDIRECT_URL}`}
          usePopup={true}
          scope="name email"
          responseType="code id_token"
          responseMode="form_post"
          callback={appleResponse}
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <a
              href="#"
              className="button button-black bg-state-dark"
              onClick={renderProps.onClick}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/svg/apple-black-dark.svg")}
                className="theme-light-show h-15px me-3"
              />
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/svg/apple-black.svg")}
                className="theme-dark-show h-15px me-3"
              />
              <span className="indicator-label-small">
                {intl.formatMessage({ id: "LOGIN.WITH.APPLE" })}
              </span>
            </a>
          )}
        />

        <p style={{ fontFamily: "none" }}>{JSON.stringify(response)}</p>

       
      </div>
      */}
      {/* end::Login options */}
    </form>
  );
}
