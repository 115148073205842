/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../../app/modules/auth";
import {
  UsersChartWidget,
  CompaniesTableWidget,
  UsersByFacilityTableWidget,
  BriefChangeChartsWidget,
  TotalChangesWidget,
} from "../../../_metronic/partials/widgets";

import { ExportPDFButton } from "../../../_metronic/layout/components/header/ExportPDFButton";

type Props = {
  rolId: number;
};
const DashboardPage: FC<Props> = ({ rolId }) => (
  <>
    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      <div className="col-xxl-6">
        <UsersChartWidget className="card-xxl-stretch mb-xl-3" />
      </div>
      <div className="col-xxl-6">
        {rolId == 1 ? (
          <CompaniesTableWidget
            className="card-xxl-stretch mb-xl-3"
            title="NEW.COMPANIES"
          />
        ) : (
          <UsersByFacilityTableWidget
            className="card-xxl-stretch mb-xl-3"
            title="USERS.BY.FACILITY"
          />
        )}
      </div>
    </div>
    {/* end::Row */}

    <div className="row g-5 gx-xxl-8">
      <div className="col-xxl-8">
        <BriefChangeChartsWidget className="card-xl-stretch mb-5 mb-xl-8" />
      </div>
      <div className="col-xxl-4">
        <TotalChangesWidget
          className="card-xl-stretch text"
          chartColor="danger"
          chartHeight="310px"
        />
      </div>
    </div>
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  return (
    <div className="position-relative">
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>

      <ExportPDFButton />

      <DashboardPage rolId={Number(currentUser?.rol_id)} />
    </div>
  );
};

export { DashboardWrapper };
